import React from 'react'

import {Grid, GridCell, GridRow} from '@rmwc/grid'
//import '@rmwc/grid/styles'
import '@material/layout-grid/dist/mdc.layout-grid.css';
import './PrivacyStatement.css'

const PrivacyStatement = ()=>{

    return (
        <Grid id="privacy-main">
                <GridCell  className="header-cell" phone={4} tablet={8} desktop={12}>
                  
                     <div>
                        Privacy Statement
                    </div>
                    <div>
                        {/* The below policy applies to all information 
                        collected by GoalTasks on Mac Platform */}
                        The below policy applies to all information 
                        collected by Currency Base on iOS Platform
                    </div>  
                </GridCell>
                         <GridCell className="privacy-item-cell" phone={4} tablet={8} desktop={12}>
                            <div>Information Collected</div>
                            <div>
                              Currency Base does not collect any personal information from it users.
                                 There are no ads(targeted or otherwise) or third party links within the app
                            </div>
                        </GridCell> 
                        <GridCell className="privacy-item-cell" phone={4}   tablet={8} desktop={12}>
                            <div>User Consent</div>
                            <div>
                               By using Currency Base, you consent to this privacy policy
                            </div>
                        </GridCell>
                        <GridCell className="privacy-item-cell" phone={4} tablet={8} desktop={12}>
                            <div>Contact</div>
                            <div>
                                If you have any questions regarding
                                this privacy policy, please email rapiddevelopa@gmail.com
                             </div>
                        </GridCell>
                        <GridCell className="privacy-item-cell" phone={4} tablet={8} desktop={12}>
                            <div>Policy History</div>
                            <div>
                               February 18, 2021: First Published
                             </div>
                        </GridCell> 
        </Grid>
    )
}

export default PrivacyStatement