import React, { Fragment } from "react";

import { Typography } from "@rmwc/typography";
import '@rmwc/typography/styles'

import {Grid, GridCell, GridRow} from '@rmwc/grid'
import '@rmwc/grid/styles'

import appImg from '../../assets/currencyBase/homescreen.png'
import appIcon from '../../assets/currencyBase/appIcon.png'
import featureImg1 from '../../assets/currencyBase/favwidget.png'
import featureImg2 from '../../assets/currencyBase/visualize.png'
import featureImg3 from '../../assets/currencyBase/autoconvert.png'
import featureImg4 from '../../assets/currencyBase/search.png'

import downloadBtn from '../../assets/currencyBase/black.svg'


import './CurrencyCalc.css'

import { CurrencyBaseFeature } from "../CurrencyBaseFeature/CurrencyBaseFeature";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const CurrencyCalc = () => {

    const handleDragStart = (e) => e.preventDefault();

     
   
    const items = [
        <CurrencyBaseFeature
          onDragStart={handleDragStart}
          imgPath={featureImg1}
          subHeading={"Favorite Rates Widget"}
          text={
            "Keep an eye on your favorite exchanges without ever re-launching the app!"
          }
        />
         ,
         <CurrencyBaseFeature
            onDragStart={handleDragStart}
            imgPath={featureImg2}
            subHeading={"Visualize Trends"}
            text={
            `Make better financial decisions by gaining insights into the behavior 
                of an exchange rate over the past 30 or 90 days`
            }
          />,
          <CurrencyBaseFeature
          onDragStart={handleDragStart}
          imgPath={featureImg3}
          subHeading={"Real Time Conversions"}
          text={
          `Save some time and get the coversions as you type `
          }
        />,
          <CurrencyBaseFeature
            onDragStart={handleDragStart}
            imgPath={featureImg4}
            subHeading={"Find Your Desired Currency"}
            text={
            "Easily search across 170 forex currencies used around the world for any currency of your choice"
            }
         />
    ];

    return (
        <div id="currencyBase">
             <Grid className="banner">
              <GridCell className={"text-container"} phone={4} tablet={8} desktop={8}>
                    <GridCell id="app-title" phone={4}>
                    <img className="app-icon" src={appIcon} />
                    <Typography>Currency Base</Typography>
                </GridCell>
                <GridCell className="tagline" phone={4}  desktop={8}>
                    <Typography>
                        Calculating Currency Exchange Rates Just Got Easier
                    </Typography>
                </GridCell>
                <GridCell className="intro" phone={4}  tablet={8}>
                    <Typography>
                        With this app, you can get the latest exchange rates at your finger tips, 
                        visualize trends and search through 170 currencies across the world
                    </Typography>
                    {/* <a href="https://apps.apple.com/us/developer/orisajobi-akinbola/id1553680431?itsct=apps_box&amp;itscg=30200" style="display: inline-block; overflow: hidden; border-top-left-radius: 13px; border-top-right-radius: 13px; border-bottom-right-radius: 13px; border-bottom-left-radius: 13px; width: 250px; height: 83px;"> */}
                    <a id="download-btn" href="https://apps.apple.com/us/app/currencybase/id1553680429?itsct=apps_box&itscg=30200">

                      <img  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&h=ebef81d474b397c3853970d4361ddcf9" alt="Download on the App Store"/>
                       {/* <img  src={downloadBtn}/> */}
                    </a>
                 </GridCell>
              </GridCell>
              <GridCell className="img-container" phone={4} tablet={8} desktop={4}>
                   <GridCell className="appImg" phone={4}  tablet={8}>
                      <img src={appImg}/>
                 </GridCell>
              </GridCell>
           
            </Grid>
            <Grid className="feature-section">
                 {/* <GridCell>
                    <div className="subheadline">Features</div>
                 </GridCell> */}
                 <GridCell phone={4} tablet={8} desktop={12}>
                     <AliceCarousel 
                       animationDuration={3500}
                       infinite
                       autoPlay={true}
                       autoPlayInterval={3500}
                      disableButtonsControls
                      mouseTracking items={items} />
                </GridCell> 
            </Grid>
        </div>
       
    )
}

export default CurrencyCalc