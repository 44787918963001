import React from "react";

import {GridCell, GridRow} from '@rmwc/grid'
import '@rmwc/grid/styles'

import { Typography } from "@rmwc/typography";
import '@rmwc/typography/styles'

import './CurrencyBaseFeature.css'

export const CurrencyBaseFeature = (props)=> {

    const {subHeading, text, imgPath} = props

    return (
        <>
            <GridCell className={"feature"} desktop={8}>
                <GridCell phone={4} tablet={8} desktop={4}>
                    <img className="feature-img" src={imgPath}/>
                </GridCell>
                <GridCell className={"feature-caption"} phone={4} tablet={8} desktop={4}>
                    <GridCell className="headline">
                        <Typography>
                            {subHeading}
                        </Typography>
                    </GridCell>
                    <GridCell className="subheadline">
                        <Typography>
                            {text}
                        </Typography>
                    </GridCell>
                </GridCell>
            </GridCell>
          
           
        </>
    )
}