import React  from "react";
import logo from './logo.svg';
import './App.css';

import { 
        Link, 
        Route,
        Switch 
      } from 'react-router-dom'



import GoalTasks from './Components/GoalTasks/GoalTasks'
import CurrencyCalc from './Components/CurrencyCalc/CurrencyCalc'
import PrivacyStatement from './Components/PrivacyStatement/PrivacyStatement'

import {TopAppBar, TopAppBarRow, TopAppBarNavigationIcon, TopAppBarSection, TopAppBarTitle, TopAppBarFixedAdjust} from '@rmwc/top-app-bar'
import '@rmwc/top-app-bar/styles'

import {
        Drawer, 
        DrawerContent, DrawerHeader,
         DrawerSubtitle, DrawerTitle
        } from '@rmwc/drawer'
import '@rmwc/drawer/styles'

import { List, ListItem } from "@rmwc/list";
import '@rmwc/list/styles'

import { IconButton } from "@rmwc/icon-button";
import '@rmwc/icon-button/styles';

import {routes} from './routes'
import ScreenUI from "./Components/ScreenUI/ScreenUI";
import About from "./Components/About/About";


function App() {

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div className="App">
        <Drawer id="drawer" modal open={open} onClose={() => setOpen(false)}>
          <DrawerHeader>
            <DrawerTitle>Menu</DrawerTitle>
            {/* <DrawerSubtitle>Subtitle</DrawerSubtitle> */}
          </DrawerHeader>
          <DrawerContent>
            <List>
               <Link className="nav-link" to={routes.home}>
                   <ListItem>Home</ListItem>
               </Link>
               <Link className="nav-link" to={routes.screens}>
                   <ListItem>Screen UI</ListItem>
               </Link>
               <Link className="nav-link" to={routes.about}>
                   <ListItem>About</ListItem>
               </Link>
            </List>
          </DrawerContent>
        </Drawer>
        <TopAppBar short>
          <TopAppBarRow>
            {/* <TopAppBarSection> */}
              <TopAppBarSection>
                <TopAppBarNavigationIcon icon="menu" onClick={() => setOpen(!open)} />
                <Link className="nav-link" to={routes.home}>
                   <TopAppBarTitle id="topbar-title">Rapid Developer</TopAppBarTitle>
                </Link>
               </TopAppBarSection>
            {/* </TopAppBarSection> */}
          </TopAppBarRow>
        </TopAppBar>
        <TopAppBarFixedAdjust  />

        <div>
          <Switch>
            <Route exact path={routes.apps}>
              <CurrencyCalc />
            </Route>
            <Route exact path="/privacy">
              <PrivacyStatement />
            </Route>
            <Route exact path={routes.home}>
               
               <CurrencyCalc />
              {/* <About /> */}
            </Route>
            <Route exact path={routes.screens}>
              <ScreenUI />
            </Route>
            <Route exact path={routes.about}>
              <About />
            </Route>
          </Switch>
        </div>
        <div className="footer">
          <Link className="link privacy item" to="/privacy">
            Privacy Statement
          </Link>
          <div className="connect item">
            <div className="link">Connect</div>
            <a className="link" href="mailto:www.rapiddevelopa@gmail.com">
              <div>email: www.rapiddevelopa@gmail.com</div>
            </a>
            <a
              className="link"
              target="_blank"
              href="https://twitter.com/rapid_developer"
            >
              <div>twitter: @rapiddevelopa</div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
