import React, {useState} from "react";
import finance1 from '../../assets/screens/finance/finance1.mp4'
import finance2 from '../../assets/screens/finance/finance2.mp4'
import finance3 from '../../assets/screens/finance/finance3.mp4'
import finance4 from '../../assets/screens/finance/finance4.mp4'
import finance5 from '../../assets/screens/finance/finance5.mp4'

import news1 from '../../assets/screens/news/news1.mp4'
import news2 from '../../assets/screens/news/news2.mp4'
import news3 from '../../assets/screens/news/news3.mp4'


import ReactPlayer from "react-player";

import { Card, CardPrimaryAction, CardMedia } from "@rmwc/card";
import "@rmwc/card/styles"

import { Typography } from "@rmwc/typography";
import '@rmwc/typography/styles'

import './ScreenUI.css'
const ScreenUI = ()=> {

    let [playing, setPlaying] = useState(false)
    
    const categories = [
      {
        name: "finance",
        videos: [
            {
                title: "home",
                category: "finance",
                videoPath: finance4,
              },
          {
            title: "history",
            category: "finance",
            videoPath: finance5,
          },
          {
            title: "payments",
            category: "finance",
            videoPath: finance1,
          },
          {
            title: "pin code",
            category: "finance",
            videoPath: finance2,
          },
          {
            title: "financial plan",
            category: "finance",
            videoPath: finance3,
          }
        ]
      },
      {
        name: "news",
        videos: [
          {
            title: "Dashboard",
            category: "news",
            videoPath: news1,
          },
          {
            title: "roaster",
            category: "news",
            videoPath: news2,
          },
          {
            title: "sport schedule",
            category: "news",
            videoPath: news3,
          },
        ]
      }
    ];


       
   const renderItem = video => {
         return (
            
          <Card key={video.title} outlined className="card-item"  >
            {/* <CardPrimaryAction  onClick={()=>  setPlaying(!playing)}> */}
              <ReactPlayer
                 onClick={()=> console.log("playing") /*setPlaying(!playing)*/}
                //   playing={playing}
                  controls
                  className="react-player"
                  url={video.videoPath}
                  width={video.category === "finance" ? "50%" : "300px"}
                  height={video.category === "finance" ? "100%" : "400px"}
              />
 
              <div style={{ padding: "0 1rem 1rem 1rem" }}>
                <Typography use="headline6" tag="h2">
                  {video.title + " Screen"}
                </Typography>
                <Typography
                  use="subtitle2"
                  tag="h3"
                  theme="textSecondaryOnBackground"
                  style={{ marginTop: "-1rem" }}
                >
                  {video.category}
                </Typography>
                <Typography
                  use="body1"
                  tag="div"
                  theme="textSecondaryOnBackground"
                >
                </Typography>
              </div>
            {/* </CardPrimaryAction> */}
          </Card>
            
           
         )
   }
    

    return (
      <div className="container">
        <div className="tagline">UI Screens</div>
        <div className="screen-intro intro">
           This page includes different screens from different categories of apps
           that I implemented using SwiftUI on iOS and iPadOS. To see the fluid user interactions
           that results from each beautiful UI, tap on the playbutton on each video.
        </div>
        {
            categories.map((category)=> {
                return (
                    <div className="category category-title" key={category.name}>
                        <div className="headline">{category.name}</div>
                        <div className="videos">
                            {
                                category.videos.map(video => (
                                    renderItem(video)
                                ))
                            }
                        </div>
                    </div>
                )
            })
        }
        
      </div>
    );
}

export default ScreenUI