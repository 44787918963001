import React from 'react'
import PropTypes from 'prop-types'
//import {Helmet} from 'react-helmet'
/**
 * Material Components
 */
import {Typography} from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import {Grid, GridCell} from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import {Button} from '@rmwc/button';
import '@rmwc/button/styles';

import me from '../../assets/me.jpg'

import './About.css'
//import ReactGA from 'react-ga'

const About = ()=> {
   // ReactGA.pageview("/about");  
    return (
        <div className="about-grid-container">
              {/* <Helmet>
                    <meta charSet="utf-8" />
                    <title>Rapid Interactive</title>
                    <link rel="canonical" href="https://rapid-portfolio.herokuapp.com/about" />
                </Helmet>   */}
             <Grid className="about-grid">
             <GridCell phone={4}  tablet={8} desktop={12} className="about-cell">
                     {/* <Typography use="headline5" tag="div" className="intro">About</Typography> */}
                      <div>
                          <img className="about-pic" src={me} alt='me'/>
                          <span>Orisajobi Akinbola</span>
                      </div>
                      <Typography use="body1" tag="div">
                            Orisajobi Akinbola (@Rapid Developer) is a a software developer with 2 years of experience in iOS development and 2 years of experience in data visualization. 
                            I build products that provide a delightful data-driven experiences. I love building digital experiences for the Apple ecosystem. I specialize in Swift and iOS. I also have some experience with scripting HTML, CSS and programminngJavascript. I'm a Statistics graduate of the University 
                            of Calabar interested in iOS development and Data Visualization 
                      </Typography>
                 </GridCell>
                 {/* <GridCell phone={4} tablet={8} desktop={12} className="about-cell">
                     <Typography use="headline5" tag="div" className="about-subtitle1">About</Typography>
                     <Typography use="body1" tag="div">
                             Rapid Interactive is a data visualization agency that focuses on using new technologies
                              to connect people to complex data. My works span dashboard development and interactive data storytelling.
                      </Typography>
                 </GridCell> */}
                 <GridCell phone={4}  tablet={8} desktop={12} className="about-cell">
                     <Typography use="headline5" tag="div" className="about-subtitle1">Skills</Typography>
                      <ul>
                          <li><Typography use="body1" tag="div">iOS/iPadOS programming</Typography></li>
                         <li><Typography use="body1" tag="div">Swift programming</Typography></li>
                         <li><Typography use="body1" tag="div">HTML, CSS</Typography></li>
                         <li><Typography use="body1" tag="div">Data Visualization</Typography></li>
                      </ul>
                      
                      <a id="open-cv" target="_blank" href="https://docs.google.com/document/d/1dT711nbE7q6DFv0XsH5NHnlm00VnTCe8Y2ttk8X10vk/edit?usp=sharing">
                         <Button className="btn" label="Open CV" raised />
                      </a>
                    

                 </GridCell>
                
             </Grid>

        </div>
    )
}

export default About

 